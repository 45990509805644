import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AdminService } from '../admin.service';
import { AdminAuthService } from '../admin-auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
	innerHeight: any = '';
	hide: boolean = false;
	captechBasURL = environment.captechBaseURL; // captech base URL..

	constructor(
		private router: Router,
		private adminService: AdminService,
		private authService: AdminAuthService
	) { }

	ngOnInit() {
		this.innerHeight = window.innerHeight;
	}

	// get JWT token on login button click..
	loginUser(email: string, password: string) {
		this.adminService.authenticateUser(email, password).subscribe(res => {
			if (res) {
				this.authService.setTokenInSession(res.token);
				console.log('user Token ', sessionStorage.getItem('user'));
				this.router.navigate(['sub-contractors/', 'successfull'])
			} else {
				console.log('Oops something went wrong');
			}
		}, error => {
			console.log('You are not authorised', error);
		})
	}
}
