import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  lang = ''
  constructor(private translate: TranslateService, private router: ActivatedRoute) { }

  ngOnInit() {
    this.router.queryParams.subscribe(param => {
      this.lang = param['lang']
    })

    console.log('this.lang', this.lang)
    
    this.translate.setDefaultLang(this.lang);
    
  }

}
