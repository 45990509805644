import { City } from "./labour-model/city";
import { Entity } from "./labour-model/entity";
import { LabourContractorDocuments } from "./labour-model/labour-contractor-documents";
import { LabourContractorGeoLocation } from "./labour-model/labour-contractor-geo-location";
import { LabourContractorPreferredLocation } from "./labour-model/labour-contractor-preferred-location";
import { LabourContractorProjectType } from "./labour-model/labour-contractor-project-type";
import { LabourContractorReference } from "./labour-model/labour-contractor-reference";

export class LabourContractor {
    company_name: string;
    establishment_year: string;
    address1: string;
    address2: string;
    city: City;
    owner_name: string;
    email_address: string;
    mobile_no: string;
    pincode: string;
    landmark: string;
    entity: Entity;
    no_of_projects_completed: number;
    gst_no: string;
    pan_no: string;
    pf_no: string;
    project_type: Array<LabourContractorProjectType>;
    labourcontractorlocation_set: Array<LabourContractorGeoLocation>;
    labourcontractorpreferredlocation_set: Array<LabourContractorPreferredLocation>;
    no_of_projects_licenses: number;
    aadhaar_no: string;
    is_aadhaar_copy_verified: true;
    references: string;
    lab_contractor_documents_set: Array<LabourContractorDocuments>;
    created_date: Date;
    dob: Date;   
    is_active: boolean;
    is_gst_verified: boolean;
    is_pan_verified: boolean;
    source: number;
    whatsapp_no: string;
    labourcontractorreference_set: Array<LabourContractorReference>;
}

