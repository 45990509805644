import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-regd-lc',
  templateUrl: './pre-regd-lc.component.html',
  styleUrls: ['./pre-regd-lc.component.css']
})
export class PreRegdLcComponent implements OnInit {

  inputVal;

  constructor() { }

  ngOnInit() {
    this.inputVal = 'Text'
  }

}
