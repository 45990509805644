import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { OurAppComponent } from './our-app/our-app.component';
import { PreRegdGcComponent } from './pre-regd-gc/pre-regd-gc.component';
import { PreRegdLcComponent } from './pre-regd-lc/pre-regd-lc.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FounderComponent } from './founder/founder.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { OurAssociateComponent } from './our-associate/our-associate.component';
import { MediaCoverageComponent } from './media-coverage/media-coverage.component';
import { LabourContractorRegistrationComponent } from './labour-contractor-registration/labour-contractor-registration.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { LoginComponent } from './admin/login/login.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { WebLayoutComponent } from './layouts/web-layout/web-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LabourRegistrationListComponent } from './admin/dashboard/labour-registration/labour-ragistration-list/labour-registration-list.component';
import { LabourRegistrationDetailComponent } from './admin/dashboard/labour-registration/labour-registration-detail/labour-registration-detail.component';
import { UserComponent } from './admin/dashboard/user/user.component';
import { UserCrudComponent } from './admin/dashboard/user/user-crud/user-crud.component';
import { SubContractorComponent } from './sub-contractor/sub-contractor.component';
import { CareersComponent } from './careers/careers.component';

const appRoutes: Routes = [

    // Web layout..
    { path: '', component: WebLayoutComponent, children: 
        [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent },
            { path: 'our-associate-company', component: OurAssociateComponent },
            { path: 'founder', component: FounderComponent },
            { path: 'pre-regd-general-contractor', component: PreRegdGcComponent },
            { path: 'pre-regd-labour-contractor', component: PreRegdLcComponent },
            { path: 'media-coverage', component: MediaCoverageComponent },
            { path: 'contact-us', component: ContactUsComponent },
            { path: 'careers', component: CareersComponent },
            { path: 'privacy-policy', component: PrivacyPolicyComponent },
            { path: 'terms-conditions', component: TermsConditionsComponent },
            { path: 'sub-contractor-registration/:src/:lang', component: LabourContractorRegistrationComponent },
            { path: 'thank-you', component: ThankYouComponent },
            { path: 'send-sms', component: BackofficeComponent },
            { path: 'sub-contractor/src/:id', component: SubContractorComponent },
        ]
    },

    // admin layout..
    {
        path: '', component: AdminLayoutComponent, children:
        [            
            { path: 'dashboard', component: DashboardComponent },
            { path: '', component: DashboardComponent, children: 
                [
                    { path: 'sub-contractors/:flag', component: LabourRegistrationListComponent },
                    { path: 'sub-contractors/:flag/:id', component: LabourRegistrationDetailComponent },
                    { path: 'view-users', component: UserComponent },
                    { path: 'add-user/:id', component: UserCrudComponent },
                    { path: 'update-user/:id', component: UserCrudComponent },
                   
                ]
            }
        ]
    },

    

    { path: 'login', component: LoginComponent },
    { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}

export const routingComponent = [
    HomeComponent, PreRegdGcComponent, PreRegdLcComponent, ContactUsComponent, FounderComponent, 
    PrivacyPolicyComponent, TermsConditionsComponent, OurAssociateComponent, MediaCoverageComponent,
    LabourContractorRegistrationComponent, ThankYouComponent, BackofficeComponent, LoginComponent,
    DashboardComponent, WebLayoutComponent, AdminLayoutComponent, LabourRegistrationListComponent, 
    LabourRegistrationDetailComponent, UserComponent, UserCrudComponent, CareersComponent
]
