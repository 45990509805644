import { Component, OnInit } from '@angular/core';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-pre-regd-gc',
  templateUrl: './pre-regd-gc.component.html',
  styleUrls: ['./pre-regd-gc.component.css']
})
export class PreRegdGcComponent implements OnInit {


    // Declare height and width variables
    scrHeight:any;
    scrWidth:any;

    @HostListener('window:resize', ['$event'])


  imageObject = [
    
    {
    video: 'https://www.youtube.com/watch?v=054IhANcoN0&autoplay=0&showinfo=0&controls=1&rel=0',
    title: 'Why eFORCE ?',
    alt: 'youtube video'
}, 
    {
    video: 'https://www.youtube.com/watch?v=az5z4xldx5s&autoplay=0&showinfo=0&controls=1&rel=0',
    title: 'How to add Requisition in eFORCE V 2.0 (English)',
    alt: 'youtube video'
}, {
    video: 'https://www.youtube.com/watch?v=mNydOtpUNH8&autoplay=0&showinfo=0&controls=1&rel=0',
    title: 'eFORCE V २.० पे सप्लाय कैसे जोडे. ( हिंदी )',
    alt: 'youtube video'
}, 
{
    video: 'https://www.youtube.com/watch?v=1khEN-JG3K4&autoplay=0&showinfo=0&controls=1&rel=0',
    title: 'eForce location usage',
    alt: 'youtube video'
}, 
{
    video: 'https://www.youtube.com/watch?v=M2xCi6yXY04&autoplay=0&showinfo=0&controls=1&rel=0',
    title: 'eFORCE by Captech Technologies (A Construction Tech Start-Up)',
    alt: 'youtube video'
}, 
    

];


refreal=[
  {no:'1',projectNo:'Project', projectType:'Residential Building', companyName:'UTF Projects', amt:'24,000 + GST / project', bonus:'2,500'},
  {no:'2', projectNo:'Project', projectType:'Commercial Building', companyName:'SQW Projects', amt:'24,000 + GST / project', bonus:'5,000'},
  {no:'5', projectNo:'Project', projectType:'Institutional Building', companyName:'JMK Projects', amt:'24,000 + GST / project', bonus:'12,500'},
  {no:'10', projectNo:'Project', projectType:'Roads and Highways', companyName:'Kumar Buildcon', amt:'24,000 + GST / project', bonus:'25,000'},
]


obj={}

  constructor() { }

  ngOnInit() {

    this.getScreenSize();

    if(this.scrWidth >=320 && this.scrWidth <=640 && this.scrWidth!<=641 )
    {
      this.obj={
        width:'100%',
        height:'30%',
      
       
      }

    }
    else
    {

      this.obj={
        width:'25%',
        // height:'25%',
      
       
      }

    }

    console.log("object val", this.obj);
    
    
  }


  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;


   

    console.log(this.scrHeight, this.scrWidth);
}


}
