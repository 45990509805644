import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateModuleConfig, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }  
}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot({})
    ],
    exports: [TranslateModule]
})

export class Translate {
}
