import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LabourContractorService } from './labour-contractor.service';

declare var $: any;

@Component({
	selector: 'app-labour-contractor-registration',
	templateUrl: './labour-contractor-registration.component.html',
	styleUrls: ['./labour-contractor-registration.component.css']
})
export class LabourContractorRegistrationComponent implements OnInit {

	/* Form Object */
	entityObj: any = {
		entityID: 0,
		entityList: []
	}

	city: any = {
		cityList: [],
		city_id: 0,
		stateCountryObj: { country: {}, state: {} }
	};
	selectedCityList: any = [];
	specialisationList: any = [];
	selectedSpecialisationList: any = [];
	geographicalSpreadList: any = [];
	selectedGeographicalSpreadList: any = [];
	selectedPreferredLocationList: any = [];
	reference1 = { seq_no: "1", ref_name: "", ref_company_name: "", ref_project_name: "", ref_contact_no: "" };
	reference2 = { seq_no: "2", ref_name: "", ref_company_name: "", ref_project_name: "", ref_contact_no: "" };

	/* Validation Flags */
	isFormValid: boolean = false;
	isPANCopyValid: boolean = false;
	isGSTCopyValid: boolean = false;
	isPFCopyValid: boolean = false;
	isPanDuplicate: boolean = false;

	isGSTDuplicate: boolean = false;

	isReferenceOneValid: boolean = true;
	isReferenceTwoValid: boolean = true;
	isMobileDuplicate: boolean = false;
	isEmailDuplicate: boolean = false;
	isDOBValid: boolean = false;
	formLoader: boolean = false;

	/* File Format */
	fileFormatRegex = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
	mobileRegex = '^[0-9]*$';
	gstRegex = '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$';
	//A sequence of exactly two uppercase letter followed by a slash followed by a group of 5 digits followed by a slash followed by a group of 7 digits.
	//The ^ and $ will ensure that this is the complete string containing nothing else, which is OK for validating the complete string. If you want to know if s string contains such an Id you have to leave them away, and if you wnat to extract it from a string you will have to use capturing groups (enclose the regex in round brackets).
	PFRegex = '^[A-Z]{2}/\d{5}/\d{7}$';
	//pincodeRegex = '^[1-9][0-9]{5}$';
	noRegex = '';

	/* Captcha */
	isRecaptchaValid: boolean = false;

	/* Multiselect Setting */
	multiSelectSettings = {};
	singleSelectSettings = {};

	/* source and language params */
	extraInfo: { source: string, language: string } = {
		source: '',
		language: ''
	};

	// language popup
	isPopUpOpen: boolean = true;

	// register click first time flag
	lcID: any = '';

	lang = '';

	/* Temp Variable */
	filePanCardUpload: any;
	fileGSTCardUpload: any = '';
	filePFCardUpload: any = '';
	gstCertNo: any = '';
	panCardNo: any = '';
	emailAddress: any = '';
	exceptionPopUpObj: any = {
		isPopUpOpen: false,
		popUpContent: '',
	};
	dobValue: any = '';
	panCardCopy: any = '';
	isDevenagiriUnicodes: boolean = false;
	authToke: string = 'ab2dc22a-ba9d-11ea-b3de-0242ac130004'

	constructor(
		private router: Router,
		private labourService: LabourContractorService,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private http: HttpClient
	) { }

	ngOnInit() {

		console.log("form value--->",);
		
		// get all entity
		this.getEntity();

		// get all city
		this.getCity();

		// get all specialisation
		this.getSpecialisation();

		// multiselect settings..
		this.multiSelectSettings = {
			text: "Please Select",
			//selectAllText: 'Select All',
			//unSelectAllText: 'UnSelect All',
			classes: "myclass custom-class",
			enableSearchFilter: true,
			lazyLoading: true,
		};

		// single select setting..
		this.singleSelectSettings = {
			text: "Please Select",
			classes: "myclass custom-class",
			enableSearchFilter: true,
			lazyLoading: true,
			singleSelection: true,
		}

		// get query params..
		this.route.params.subscribe(params => {
			this.extraInfo.source = params.src;
			this.extraInfo.language = params.lang;

			// set Language..			
			this.lang = this.extraInfo.language == 'english' ? 'en-US' : 'hi-IN';
			this.translate.setDefaultLang(this.lang);
		});

		/* Datepicker */
		$(".datepicker").datepicker({
			format: "yyyy",
			viewMode: "years",
			minViewMode: "years",
			autoclose: true,
			endDate: '+0d',
			startDate: '-300y'
		});

		// DOB
		$(".DOB").datepicker({
			format: 'yyyy-mm-dd',
			autoclose: true,
			endDate: '-18y',
			startDate: '-100y'
		});

		// ask language..
		this.askLanguage();
	}

	// ask language..
	askLanguage() {
		if (this.extraInfo.source == '0') {
			this.isPopUpOpen = true
		} else {
			this.isPopUpOpen = false
		}
	}

	// set langugage
	setLanguage(language) {
		this.lang = language;
		this.translate.setDefaultLang(language);
		this.isPopUpOpen = false;
	}

	// get entities..
	getEntity() {
		this.labourService.getRequest('get-entities/').subscribe(res => {
			this.entityObj.entityList = res;
		}, error => {
			console.error('error', error);
		})
	}

	// get city..
	getCity() {
		this.labourService.getRequest('get-cities/').subscribe(data => {
			this.geographicalSpreadList = data;
			let regxVal = new RegExp('city_name', 'g');
			this.geographicalSpreadList = JSON.parse(JSON.stringify(this.geographicalSpreadList).replace(regxVal, 'itemName'));
		}, error => {
			console.error('[error] ', error);
		});

		// this.labourService.request('get','city/get/',null).subscribe(res=>{
		// 	console.log("result-->",res);
			
		// })
	}

	// get state and country..
	getStateAndCountry(event) {
		this.labourService.getRequest('get-state-country-by-cityId/' + event.id).subscribe((data) => {
			this.city.stateCountryObj = data;
		}, error => {
			console.error('[error] ', error);
		})
	}

	// get specialisation..
	getSpecialisation() {
		this.labourService.getRequest('get-project-type/').subscribe(data => {
			this.specialisationList = data;
			let regxVal = new RegExp('project_type_name', 'g');
			this.specialisationList = JSON.parse(JSON.stringify(this.specialisationList).replace(regxVal, 'itemName'));
		}, error => {
			console.error('[error] ', error);
		})
	}

	/* References One Validation */
	referenceOneValidation() {
		if (this.reference1.ref_name != '' && (this.reference1.ref_company_name == '' || this.reference1.ref_project_name == '' || this.reference1.ref_contact_no == '')) {
			this.isReferenceOneValid = false;
		} else if (this.reference1.ref_company_name != '' && (this.reference1.ref_name == '' || this.reference1.ref_project_name == '' || this.reference1.ref_contact_no == '')) {
			this.isReferenceOneValid = false;
		} else if (this.reference1.ref_contact_no != '' && (this.reference1.ref_name == '' || this.reference1.ref_company_name == '' || this.reference1.ref_project_name == '')) {
			this.isReferenceOneValid = false;
		} else if (this.reference1.ref_project_name != '' && (this.reference1.ref_name == '' || this.reference1.ref_company_name == '' || this.reference1.ref_contact_no == '')) {
			this.isReferenceOneValid = false;
		} else {
			this.isReferenceOneValid = true;
		}
	}

	/* References Two Validation */
	referenceTwoValidation() {
		if (this.reference2.ref_name != '' && (this.reference2.ref_company_name == '' || this.reference2.ref_project_name == '' || this.reference2.ref_contact_no == '')) {
			this.isReferenceTwoValid = false;
		} else if (this.reference2.ref_company_name != '' && (this.reference2.ref_name == '' || this.reference2.ref_project_name == '' || this.reference2.ref_contact_no == '')) {
			this.isReferenceTwoValid = false;
		} else if (this.reference2.ref_contact_no != '' && (this.reference2.ref_name == '' || this.reference2.ref_company_name == '' || this.reference2.ref_project_name == '')) {
			this.isReferenceTwoValid = false;
		} else if (this.reference2.ref_project_name != '' && (this.reference2.ref_name == '' || this.reference2.ref_company_name == '' || this.reference2.ref_contact_no == '')) {
			this.isReferenceTwoValid = false;
		} else {
			this.isReferenceTwoValid = true;
		}
	}

	// only number on keypress to specific inputs..
	onlyNumbers(event: any) {
		var charCode = (event.which) ? event.which : event.keyCode
		if ((charCode >= 48 && charCode <= 57))
			return true;
		return false;
	}

	// validate DOB
	validateDOB() {
		setTimeout(() => {
			if ($('.DOB').val() != '') {
				this.isDOBValid = true;
				this.dobValue = $('.DOB').val();
			} else {
				this.isDOBValid = false;
			}
		}, 200);
	}

	// check duplicate..
	checkDuplicate(value, type) {
		if (value.length == 10 && type == 'pancard') {
			this.exceptionPopUpObj.isPopUpOpen = false;
			this.labourService.getRequest('filter-duplicate-check?pan=' + value).subscribe(res => {
				if (res.length) {
					console.log(res);
					this.isPanDuplicate = true;
					this.showPopUpMsg('exceptionMessages.panDuplicate', true);
				} else {
					this.labourService.eforcePost('/labour_contractors/pan-verify?token=$cap$speed%26eforce', { "pan_no": value }).subscribe(res => {
						if (res.is_pan_exists) {
							this.isPanDuplicate = true;
							this.showPopUpMsg('exceptionMessages.panDuplicate', true);
						} else {
							this.isPanDuplicate = false;
						}
					})
				}
			});
		}

		if (value.length == 15 && type == 'gst') {
			this.exceptionPopUpObj.isPopUpOpen = false;
			this.labourService.getRequest('filter-duplicate-check?gst=' + value).subscribe(res => {
				if (res.length) {
					this.isGSTDuplicate = true;
					this.showPopUpMsg('exceptionMessages.gstDuplicate', true);
				} else {
					this.labourService.eforcePost('/labour_contractors/gst-verify?token=$cap$speed%26eforce', { "gst_no": value }).subscribe(res => {
						if (res.is_gst_exists) {
							this.isGSTDuplicate = true;
							this.showPopUpMsg('exceptionMessages.gstDuplicate', true);
						} else {
							this.isGSTDuplicate = false;
						}
					})
				}
			});
		}

		if (value.length > 6 && type == 'email') {
			this.exceptionPopUpObj.isPopUpOpen = false;
			this.labourService.getRequest('filter-duplicate-check?email=' + value).subscribe(res => {
				if (res.length) {
					console.log('res', res);
					this.isEmailDuplicate = true;
					this.showPopUpMsg('exceptionMessages.emailDuplicate', true);
				} else {
					this.labourService.eforcePost('/users/lc-email-verify?token=$cap$speed%26eforce', { "email_address": value }).subscribe(res => {
						if (res.is_email_exist) {
							this.isEmailDuplicate = true;
							this.showPopUpMsg('exceptionMessages.emailDuplicate', true);
						} else {
							this.isEmailDuplicate = false;
						}
					})
				}
			});
		}

		if (value.length == 10 && type == 'mobileno') {
			this.exceptionPopUpObj.isPopUpOpen = false;
			this.labourService.getRequest('filter-duplicate-check?mobileNo=' + value).subscribe(res => {
				if (res.length) {
					this.isMobileDuplicate = true;
					this.showPopUpMsg('exceptionMessages.mobileDuplicate', true);
				} else {
					this.labourService.eforcePost('/users/lc-mobile-verify?token=$cap$speed%26eforce', { "mobile_no": value }).subscribe(res => {
						if (res.is_mobile_exist) {
							this.isMobileDuplicate = true;
							this.showPopUpMsg('exceptionMessages.mobileDuplicate', true);
						} else {
							this.isMobileDuplicate = false;
						}
					});
				}
			});
		}
	}

	// create form data..
	createFormData(formValue) {
		const formDataObj = new FormData();
		// console.log('form value', formValue.value);

		formDataObj.append('company_name', formValue.company_name);
		formDataObj.append('establishment_year', $("#txtYearOfEstablishment").val())
		formDataObj.append('address1', formValue.address1);
		formDataObj.append('address2', formValue.address2);
		formDataObj.append('city_id', formValue.city_id[0].id); //formValue.city_id.id
		formDataObj.append('city', formValue.city_id[0].id);
		formDataObj.append('owner_name', formValue.owner_name);
		formDataObj.append('email_address', formValue.email_address);
		formDataObj.append('mobile_no', formValue.mobile_no);
		formDataObj.append('pincode', formValue.pincode);
		formDataObj.append('landmark', formValue.landmark);
		formDataObj.append('entity_id', formValue.entity_id);
		formDataObj.append('entity', formValue.entity_id);
		formDataObj.append('no_of_projects_completed', formValue.no_of_projects_completed == '' ? 0 : formValue.no_of_projects_completed);
		formDataObj.append('gst_no', formValue.gst_no);
		formDataObj.append('pan_no', formValue.pan_no);
		formDataObj.append('pf_no', formValue.pf_no);
		formDataObj.append('is_gst_verified', '0');
		formDataObj.append('is_pan_verified', '0');
		formDataObj.append('source', this.extraInfo.source.toLowerCase());
		formDataObj.append('whatsapp_mobile_no', formValue.whatsapp_mobile_no == undefined || formValue.whatsapp_mobile_no == '' ? '' : formValue.whatsapp_mobile_no);
		formDataObj.append('dob', $(".DOB").val());

		// console.log('formDataObj ', formDataObj);

		// formDataObj.forEach(item => {
		// 	console.log('Form Data ', item[0] + ' - ' + item[1])
		// });

		// project type 
		formValue.project_type.map((item, index) => {
			formDataObj.append('project_type[' + index + ']project_type_id', formValue.project_type[index].id);
		});

		// location..
		formValue.locations.map((item, index) => {
			formDataObj.append('locations[' + index + ']location_id', formValue.locations[index].id);
			formDataObj.append('locations[' + index + ']location_type', 'City');
		});

		// preferred locations..
		formValue.preferred_locations.map((item, index) => {
			formDataObj.append('preferred_locations[' + index + ']location_id', formValue.preferred_locations[index].id);
			formDataObj.append('preferred_locations[' + index + ']location_type', 'City');
		});

		formDataObj.append('no_of_projects_licenses', '0');

		// reference 
		const referenceArray = [];
		if (this.reference1.ref_name != '' && this.reference1.ref_project_name != '' && this.reference1.ref_company_name != '' && this.reference1.ref_contact_no != '') {
			referenceArray.push(this.reference1)
		}

		if (this.reference2.ref_name != '' && this.reference2.ref_project_name != '' && this.reference2.ref_company_name != '' && this.reference2.ref_contact_no != '') {
			referenceArray.push(this.reference2)
		}

		if (referenceArray.length == 1) {
			referenceArray.push({
				seq_no: '2',
				ref_name: '',
				ref_company_name: '',
				ref_project_name: '',
				ref_contact_no: ''
			})
		}

		console.log('[referenceArray] ', referenceArray);

		referenceArray.map((item, index) => {
			formDataObj.append('references[' + index + ']seq_no', item.seq_no);
			formDataObj.append('references[' + index + ']ref_name', item.ref_name);
			formDataObj.append('references[' + index + ']ref_company_name', item.ref_company_name);
			formDataObj.append('references[' + index + ']ref_project_name', item.ref_project_name);
			formDataObj.append('references[' + index + ']ref_contact_no', item.ref_contact_no);
		});




		return formDataObj;
	}

	
	// file upload..
	onPANUpload(event) {
		this.filePanCardUpload = event.target.files;
		if (this.filePanCardUpload != '') {
			this.isPANCopyValid = this.fileFormatRegex.test(event.target.files[0].name)
		}
	}

	onGSTUpload(event) {
		this.fileGSTCardUpload = event.target.files;
		if (this.fileGSTCardUpload != '') {
			this.isGSTCopyValid = this.fileFormatRegex.test(event.target.files[0].name)
		}
	}

	onPFUpload(event) {
		this.filePFCardUpload = event.target.files;
		if (this.filePFCardUpload != '') {
			this.isPFCopyValid = this.fileFormatRegex.test(event.target.files[0].name)
		}
	}
	// file upload..

	// check for devenagiri unicodes..
	restrictDevenagiriUnicodes(formValue) {
		let devenagiriUnicodesRegex = /[\u0900-\u097F]+/;
		let devenagiriUnicodesArray = [];
		//let isDevenagiriUnicodes = false;
		for (const formProp in formValue) {
			if (devenagiriUnicodesRegex.test(formValue[formProp])) {
				devenagiriUnicodesArray.push(formValue[formProp]);
			}
		}

		console.log('devenagiriUnicodes ', devenagiriUnicodesArray);

		if (devenagiriUnicodesArray.length) {
			return true;
		}
	}

	// upload document..
	updateCommonAPIs(lcID, formDataObj) {
		//debugger;
		this.formLoader = true;
		if (this.filePanCardUpload) {
			// upload file objects;
			const uploadFilesObj = new FormData();
			uploadFilesObj.append('document_type', 'pan card');
			uploadFilesObj.append('lab_contractor', lcID); ``
			uploadFilesObj.append('file_path', this.filePanCardUpload[0]);
			// POST upload document API for pan card..
			this.labourService.postRequest('create-lc-documents/', uploadFilesObj).subscribe(res => {
				console.log('Upload res ', res);
			});
		}

		if (this.fileGSTCardUpload) {
			const uploadFilesObj = new FormData();
			uploadFilesObj.append('document_type', 'gst');
			uploadFilesObj.append('lab_contractor', lcID);
			uploadFilesObj.append('file_path', this.fileGSTCardUpload[0]);
			// POST upload document API for gst..
			this.labourService.postRequest('create-lc-documents/', uploadFilesObj).subscribe(res => {
				console.log('Upload res ', res);
			});
		}

		if (this.filePFCardUpload) {
			const uploadFilesObj = new FormData();
			uploadFilesObj.append('document_type', 'pf');
			uploadFilesObj.append('lab_contractor', lcID);
			uploadFilesObj.append('file_path', this.filePFCardUpload[0]);
			// POST upload document API for pf..
			this.labourService.postRequest('create-lc-documents/', uploadFilesObj).subscribe(res => {
				console.log('Upload res ', res);
			});
		}


		formDataObj.set('is_active', '1');

		this.labourService.putRequest('update-lc/' + lcID, formDataObj).subscribe(res => {
			console.log('post res', res);
			// EFORCE API..
			this.labourService.eforcePost('/labour_contractors/register?source=abcdefg', formDataObj).subscribe(res => {
				console.log('[EFORCE API res] ', res);
				if (res) {
					// patch API to save eForce ID
					this.labourService.patchRequest('update-lc/' + lcID, { eforce_id: res.id }).subscribe(res => {
						console.log('[update captech with eforce id] ', res);
						this.formLoader = false;
						this.router.navigateByUrl('/thank-you?lang=' + this.lang);
					})
				}
			}, () => {
				this.labourService.patchRequest('update-lc/' + lcID, { is_active: 0 }).subscribe(res => {
					console.log('res', res);
				})
				this.formLoader = false;
				this.showPopUpMsg('exceptionMessages.somethingWentWrong', true);
			})

		}, error => {
			if (error) {
				this.formLoader = false;
				this.showPopUpMsg('exceptionMessages.somethingWentWrong', true);
			}
		})
	}

	// verification for pan..
	panVerification(panNo, companyName, ownerName, lcId) {
		let responseFlag = false;
		if (panNo.length == 10) {
			let compName = companyName.toUpperCase().trim().split(' ')[0];
			let owner = ownerName.toUpperCase().trim().split(' ')[0];

			return new Promise(resolve => {
				this.labourService.postRequest('verify-pan-prod/', { 'pan_no': panNo }).subscribe(res => {
					const responseCode = res.response_code;
					let errorObject = {
						document_type: "pan",
						id_number: panNo,
						reason: res.response_code + "-" + res.response_msg,
						lab_contractor: lcId
					}

					switch (+responseCode) {
						case 99:
						case 100:
						case 104:
						case 105: {
							this.labourService.postRequest('create-failed-verfication-api/', errorObject).subscribe(res => {
								console.log('res', res);
							});
							this.showPopUpMsg('exceptionMessages.commonVerificationOops', true);
							resolve(responseFlag = false)
						}
						case 101:
							if (res.data.pan_status == "VALID") {
								if (res.data.name.trim().split(' ')[0] == compName || res.data.name.trim().split(' ')[0] == owner) {
									resolve(
										responseFlag = true
									)
								}
								else {
									this.showPopUpMsg('exceptionMessages.panVerificationName', true);
									resolve(responseFlag = false)
								}
							}
							break;
						case 102:
						case 103:
						case 110:
							this.labourService.postRequest('create-failed-verfication-api/', errorObject).subscribe(res => {
								console.log('res', res);
							});
							this.showPopUpMsg('exceptionMessages.panVerificationIncorrect', true);
							resolve(responseFlag = false)
					}
				}, error => {
					this.formLoader = false;
					this.showPopUpMsg('exceptionMessages.somethingWentWrong', true);
				})
			});
		}
	}

	// gst verification..
	gstVerification(gstNo, companyName, ownerName, lcId) {
		let responseFlag = false;
		if (gstNo.length == 15) {

			return new Promise(resolve => {
				this.labourService.postRequest('verify-gst-prod/', { 'gst_no': gstNo }).subscribe(res => {
					if (res) {
						const responseCode = res.response_code;
						let errorObject = {
							document_type: "gst",
							id_number: gstNo,
							reason: res.response_code + "-" + res.response_msg,
							lab_contractor: lcId
						}

						switch (+responseCode) {
							case 99:
							case 100:
							case 104:
							case 105:
								this.labourService.postRequest('create-failed-verfication-api/', errorObject).subscribe(res => {
									console.log('res', res);
								});
								this.showPopUpMsg('exceptionMessages.commonVerificationOops', true);
								resolve(responseFlag = false)
								break;
							case 101:
								if (res.result) {
									if (res.result.tradeNam.trim().split(' ')[0] != companyName.toUpperCase().trim().split(' ')[0]) {
										this.showPopUpMsg('exceptionMessages.gstVerificationName', true);
										resolve(responseFlag = false)
									} else {
										resolve(responseFlag = true)
									}
								}
								break;
							case 102:
							case 103:
							case 110:
								this.labourService.postRequest('create-failed-verfication-api/', errorObject).subscribe(res => {
									console.log('res', res);
								});
								this.showPopUpMsg('exceptionMessages.gstVerificationIncorrect', true);
								resolve(responseFlag = false)
						}
					}


				})
			});
		}
	}

	// random string generator
	generateRandomString(strLength) {
		let result = '';
		let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
		let charactersLength = characters.length;
		for (let i = 0; i < strLength; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	// random number generator..
	generateRandomNumber(num1, num2) {
		return Math.floor(num1 + Math.random() * num2)
	}

	// psuedo data entry..
	psuedoData(formData) {
		console.log('inside psuedoData')
		const duplicateChangeObj = {
			email: 'invalid.' + this.generateRandomString(5).toLowerCase() + '@invalid.com',
			mobileNo: '0000' + this.generateRandomNumber(100000, 900000),
			panNo: this.generateRandomString(5).toUpperCase() + this.generateRandomNumber(1000, 9000) + 'C',
			gstNo: '11' + this.generateRandomString(5).toUpperCase() + this.generateRandomNumber(1000, 9000) + 'C1Z0'
		}
		// generate random string/no

		// set value..
		formData.set('email_address', duplicateChangeObj.email);
		formData.set('mobile_no', duplicateChangeObj.mobileNo);
		formData.set('pan_no', duplicateChangeObj.panNo);
		formData.set('gst_no', duplicateChangeObj.gstNo);
		formData.set('is_active', '1');

		// update lc api
		this.labourService.putRequest('update-lc/' + this.lcID, formData).subscribe(res => {
			console.log('Duplicate reset ', res);
			this.formLoader = false;
		}, () => {
			this.formLoader = false;
			this.showPopUpMsg('exceptionMessages.somethingWentWrong', true);
		});
	}

	/* Form Submit */
	onFormSubmit(form: NgForm) {
		/* form validation flag */
		this.isFormValid = true;

		// restrict devenagiri unicodes..
		if (this.restrictDevenagiriUnicodes(form.value)) {
			this.showPopUpMsg('exceptionMessages.onlyEnglishRestriction', true);
			return false;
		}

		this.referenceOneValidation();
		this.referenceTwoValidation();

		/* Reference Validation */
		if (!this.isReferenceOneValid) {
			return false;
		}

		if (!this.isReferenceTwoValid) {
			return false;
		}

		if (this.reference1.ref_contact_no != '' && this.reference2.ref_contact_no != '') {
			if (this.reference1.ref_contact_no == this.reference2.ref_contact_no) {
				this.showPopUpMsg('validationError.refDuplicateMobileNo', true);
				return false;
			}
		}

		/* Reference Validation */

		// pancard copy validation..
		if (!this.isPANCopyValid) {
			this.showPopUpMsg('validationError.inValidPanFileFormat', true);
			return false;
		}

		// gst copy validation..
		if (this.fileGSTCardUpload != '') {
			if (!this.isGSTCopyValid) {
				this.showPopUpMsg('validationError.inValidGSTFileFormat', true);
				return false;
			}
		}

		// pf copy validation..
		if (this.filePFCardUpload != '') {
			if (!this.isPFCopyValid) {
				this.showPopUpMsg('validationError.inValidPFFileFormat', true);
				return false;
			}
		}

		// duplicate check..
		if (this.isPanDuplicate || this.isGSTDuplicate || this.isEmailDuplicate || this.isMobileDuplicate) {
			this.showPopUpMsg('exceptionMessages.allDuplicateValue', true);
			return false;
		}

		if (form.valid) {
			let formValue = form.value;
			console.log('[formValue inside form..] ', formValue)

			// form data
			const formObj = this.createFormData(formValue);

			if (this.lcID == '') {

				// check pan, mobile and email..
				this.formLoader = true;
				this.labourService.getRequest('filter-duplicate-check/?pan=' + formValue.pan_no + '&mobileNo=' + formValue.mobile_no + '&email=' + formValue.email_address + '&all=true').subscribe(res => {
					console.log('Duplicate Res ', res)
					if (res != null) {
						this.lcID = res[0].id;
						if (res.eforce_id != null || res.eforce_id != 0) {
							// call  update API.
							debugger;
							this.labourService.putRequest('update-lc/'+ this.lcID, formObj).subscribe(apiResponse => {								

								this.panVerification(formValue.pan_no, formValue.company_name, formValue.owner_name, this.lcID).then(panPromiseRes => {

									console.log('pan verification result -> ' + panPromiseRes);
									this.formLoader = false;
									if (panPromiseRes) {
										formObj.set('is_pan_verified', '1');

										console.log(formValue);

										if (formValue.gst_no.length == 15) {
											console.log('inside gst if');

											this.gstVerification(formValue.gst_no, formValue.company_name, formValue.owner_name, this.lcID).then(gstPromiseRes => {
												if (gstPromiseRes) {

													formObj.set('is_gst_verified', '1');
													if (apiResponse.id > 0) {
														console.log('gst verification result -> ' + gstPromiseRes);
														// update common APIs..
														this.updateCommonAPIs(apiResponse.id, formObj);
													}
												} else {

													formObj.set('is_gst_verified', '0');
													this.psuedoData(formObj);
												}
											})
										} else {
											if (apiResponse.id > 0) {
												console.log('inside gst else');
												formObj.set('is_gst_verified', '0');
												// update common APIs..
												this.updateCommonAPIs(apiResponse.id, formObj);
											}
										}

									} else {
										formObj.set('is_pan_verified', '0');
										formObj.set('is_gst_verified', '0');
										this.psuedoData(formObj);
									}
								});

							});
							
						} else {
							this.formLoader = false;
							// show error msg already exist..
							this.showPopUpMsg('exceptionMessages.alreadyRegisterWithUs', true);
						}
					} else {
						this.formLoader = true;
						// call post API
						// captech register API..				
						this.labourService.postRequest('register-lc/', formObj).subscribe(apiResponse => {

							console.log('register-lc ', apiResponse);
							//flag main for firsttime..
							this.lcID = apiResponse.id;


							this.panVerification(formValue.pan_no, formValue.company_name, formValue.owner_name, this.lcID).then(panPromiseRes => {

								console.log('pan verification result -> ' + panPromiseRes);
								this.formLoader = false;
								if (panPromiseRes) {
									formObj.set('is_pan_verified', '1');

									console.log(formValue);

									if (formValue.gst_no.length == 15) {
										console.log('inside gst if');

										this.gstVerification(formValue.gst_no, formValue.company_name, formValue.owner_name, this.lcID).then(gstPromiseRes => {
											if (gstPromiseRes) {

												formObj.set('is_gst_verified', '1');
												if (apiResponse.id > 0) {
													console.log('gst verification result -> ' + gstPromiseRes);
													// update common APIs..
													this.updateCommonAPIs(apiResponse.id, formObj);
												}
											} else {

												formObj.set('is_gst_verified', '0');
												this.psuedoData(formObj);
											}
										})
									} else {
										if (apiResponse.id > 0) {
											console.log('inside gst else');
											formObj.set('is_gst_verified', '0');
											// update common APIs..
											this.updateCommonAPIs(apiResponse.id, formObj);
										}
									}

								} else {
									formObj.set('is_pan_verified', '0');
									formObj.set('is_gst_verified', '0');
									this.psuedoData(formObj);
								}
							});
						}, () => {
							this.formLoader = false;
							this.showPopUpMsg('exceptionMessages.somethingWentWrong', true);
						});
					}
				});				

			} else {

				console.log("inside else -> second time")
				this.panVerification(formValue.pan_no, formValue.company_name, formValue.owner_name, this.lcID).then(panPromiseRes => {

					console.log('pan verification result -> ' + panPromiseRes);

					if (panPromiseRes) {
						formObj.set('is_pan_verified', '1');
						if (formValue.gst_no.length == 15) {
							this.gstVerification(formValue.gst_no, formValue.company_name, formValue.owner_name, this.lcID).then(gstPromiseRes => {
								if (gstPromiseRes) {
									formObj.set('is_gst_verified', '1');
									console.log('gst verification result -> ' + gstPromiseRes);
									// update common APIs..
									this.updateCommonAPIs(this.lcID, formObj);
								} else {
									formObj.set('is_gst_verified', '0');
									this.psuedoData(formObj);
								}
							})
						} else {
							console.log("inside else -> second time");
							formObj.set('is_gst_verified', '0');
							// update common APIs..
							this.updateCommonAPIs(this.lcID, formObj);
						}

					} else {
						formObj.set('is_pan_verified', '0');
						formObj.set('is_gst_verified', '0');
						this.psuedoData(formObj);
					}
				});
			}
		}
	}

	showPopUpMsg(exceptionMsg, boolVar) {
		this.exceptionPopUpObj.popUpContent = exceptionMsg;
		this.exceptionPopUpObj.isPopUpOpen = boolVar;
	}
}