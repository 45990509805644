import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-downloadapp',
  templateUrl: './home-downloadapp.component.html',
  styleUrls: ['./home-downloadapp.component.css']
})
export class HomeDownloadappComponent implements OnInit {
  appScreens = [
    {
      imgURL: '../../../assets/images/splash-screen.png',
      screeName: 'splash-screen'
    },
    {
      imgURL: '../../../assets/images/login-screen.png',
      screeName: 'login-screen'
    },
    // {
    //   imgURL: '../../../assets/images/dashboard-screen.png',
    //   screeName: 'dashboard-screen'
    // },
    // {
    //   imgURL: '../../../assets/images/menu-screen.png',
    //   screeName: 'menu-screen'
    // }
  ];

  customOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,    
    items: 1,
    nav: false,
    margin:10,
    autoplay:true,
    autoplayHoverPause:true,
    animateIn: 'fadeIn', // add this
    animateOut: 'fadeOut' // and this
  };
  constructor() { }

  ngOnInit() {
  }

}
