import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-home-slider',
	templateUrl: './home-slider.component.html',
	styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit {

	myStyle: object = {};
	myParams: object = {};
	width: number = 100;
	height: number = 100;

	constructor() { }

	ngOnInit() {
		this.myStyle = {
			'position': 'absolute',
			'width': '100%',
			'height': '100%',
			'z-index': 1,
			'top': 0,
			'left': 0,
			'right': 0,
			'bottom': 0,
		};

		this.myParams = {
			particles: {
				number: {
					value: 80,
				},
				color: {
					value: '#ffffff'
				},
				shape: {
					type: 'triangle',
				},
			}
		};
	}

}
