import { Component, OnInit, ViewChild } from '@angular/core';
import { LabourContractorService } from '../../../../labour-contractor-registration/labour-contractor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AdminService } from 'src/app/admin/admin.service';

@Component({
	selector: 'app-labour-registration-list',
	templateUrl: './labour-registration-list.component.html',
	styleUrls: ['./labour-registration-list.component.css']
})
export class LabourRegistrationListComponent implements OnInit {

	typeOfRegistration: string = '';	
	labourContractorData: any;
	labourContractorLength: number = 0;
	labourContractorDisplayedColumns: any = ['company_name', 'is_pan_verified', 'entity', 'source', 'owner_name', 'mobile_no', 'is_active', 'documents'];

	// model values
	companyName: string = '';
	ownerName: string = '';
	mobileNo: string = '';
	source: string = '';
	status: string = '';

	// pagination
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;


	constructor(
		private activateRoute: ActivatedRoute,
		private router: Router,
		private adminService: AdminService

	) { }

	ngOnInit() {

		this.activateRoute.params.subscribe(param => {
			console.log('id ', param.flag);
			this.typeOfRegistration = param.flag;
			this.resetFilter();
			// get labour list..
			this.getLabourContractorList();
		})
	}

	// get excel..
	downloadExcel() {
		// this.labourService.getRequest('download-lc-contractor-excel/').subscribe(res => {
		// 	console.log('[get Excel] ', res);
		// 	window.open(res.download_link, '_blank');
		// })
	}

	// get labour contractor list..
	getLabourContractorList() {
		console.log('this.typeOfRegistration ', this.typeOfRegistration);
		let getType = '';

		if (this.typeOfRegistration == "successfull") {
			getType = "true"
		} else {
			getType = "false"
		}

		// option para
		let optionalPara = '';

		if(this.companyName != '') {
			optionalPara += '&company_name=' + this.companyName;
		}
	
		if(this.ownerName != ''){
			optionalPara += '&owner_name=' + this.ownerName;	
		}

		if(this.mobileNo != ''){
			optionalPara += '&mobile_no=' + this.mobileNo;	
		}
		
		if(this.source == '' || this.source == 'all'){
			optionalPara += '';
		} else {
			optionalPara += '&source=' + this.source;
		}

		if(this.status == '' || this.status == 'all'){
			optionalPara += '';
		} else {
			optionalPara += '&is_active=' + this.status;
		}

		// query para..
		console.log("[optionalPara] ", optionalPara);
		
		this.adminService.getSubContractorList('get-lc-list/?is_registered=' + getType + optionalPara).subscribe(res => {
			console.log('[get list res] ', res);
			this.loadDataTable(res);	
		})
		// this.labourService.getRequest('get-lc-list/?is_registered=' + getType + optionalPara).subscribe(res => {
		// 	console.log('[get list res] ', res);
		// 	this.loadDataTable(res);
		// })


	}

	// load data tables..
	loadDataTable(dataSource) {
		this.labourContractorData = new MatTableDataSource<any>(dataSource);
		// bind data sounrce to pagination
		this.labourContractorData.paginator = this.paginator;
		this.labourContractorData.sort = this.sort;
		this.labourContractorLength = this.labourContractorData.data.length;
		//console.log('[get list res] ', this.labourContractorData.data);
	}

	//reset search filter
	resetFilter(){
		this.companyName = '';
		this.ownerName = '';
		this.mobileNo = '';
		this.source = '';
		this.status = '';
	}

	// get id..
	redirectToDetailPage(id){
		console.log('obj', id);
		this.router.navigate(['/sub-contractors', this.typeOfRegistration, id])
	}
}
