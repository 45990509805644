import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { TRANSLATE_MODULE_CONFIG } from './translate.model';


// error logging and http client..
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";


/* third party plugins */
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatIconModule, MatTableModule, MatTooltipModule, MatSelectModule, MatSidenavModule, MatExpansionModule, MatPaginatorModule, MatSortModule, MatTabsModule, MatChipsModule } from '@angular/material';
import { SimplebarAngularModule } from 'simplebar-angular';

/* common imports */
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeSliderComponent } from './home/home-slider/home-slider.component';
import { HomeSlider1Component } from './home/home-slider1/home-slider1.component';
import { HomeDownloadappComponent } from './home/home-downloadapp/home-downloadapp.component';
import { HomeTestimonialComponent } from './home/home-testimonial/home-testimonial.component';
import { TestimonialComponent } from './home/home-testimonial/testimonial/testimonial.component';
import { HomeClientsComponent } from './home/home-clients/home-clients.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { OurAppComponent } from './our-app/our-app.component';
import { KeyFeaturesComponent } from './home/key-features/key-features.component';
import { PopUpComponent } from './pop-up/pop-up.component';

import { FileValueAccessorDirective } from './labour-contractor-registration/file-value-accessor.directive';

/* route module */
import { AppRoutingModule, routingComponent } from './app-routing-module';
import { SubContractorComponent } from './sub-contractor/sub-contractor.component';
import { HttpInterceptorService } from './http-interceptor.service';
import { NgSelectModule } from '@ng-select/ng-select';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";
// import { MatMomentDateModule } from "@angular/material-moment-adapter";

import { MAT_DATE_FORMATS } from '@angular/material/core';

import { NgImageSliderModule } from 'ng-image-slider';



export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@NgModule({
  declarations: [
    // common component imports
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeSliderComponent,
    HomeSlider1Component,
    HomeDownloadappComponent,
    HomeTestimonialComponent,
    TestimonialComponent,
    HomeClientsComponent,
    UnderConstructionComponent,
    OurAppComponent,
    KeyFeaturesComponent,
    PopUpComponent,
    FileValueAccessorDirective,

    // routing component (which includes all routing components)
    routingComponent,

    SubContractorComponent
  ],
  imports: [
    BrowserModule,
    CarouselModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
    //Material Module..
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatSelectModule,
    MatSidenavModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatChipsModule,
    // scroll bar..
    SimplebarAngularModule,
    NgxSpinnerModule,
    NgSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // MatMomentDateModule
    NgImageSliderModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
