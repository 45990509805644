import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.css']
})
export class PopUpComponent implements OnInit {
  @Input() popUpInputObj: any;  

  constructor() { }

  ngOnInit() {    
  }

  hidePopUp(){
    this.popUpInputObj.isPopUpOpen = false;
  }
}
