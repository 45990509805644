import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-associate',
  templateUrl: './our-associate.component.html',
  styleUrls: ['./our-associate.component.css']
})
export class OurAssociateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
