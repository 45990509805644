import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-crud',
  templateUrl: './user-crud.component.html',
  styleUrls: ['./user-crud.component.css']
})
export class UserCrudComponent implements OnInit {
  
  hide: boolean =false;

  constructor() { }

  ngOnInit() {
  }

}
