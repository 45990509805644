import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LabourContractorService } from '../../../../labour-contractor-registration/labour-contractor.service';
import { LabourContractor } from 'src/app/labour-contractor-registration/labour.model';
import { City } from 'src/app/labour-contractor-registration/labour-model/city';
import { Entity } from 'src/app/labour-contractor-registration/labour-model/entity';
import { State } from 'src/app/labour-contractor-registration/labour-model/state';
import { Country } from 'src/app/labour-contractor-registration/labour-model/country';
import { LabourContractorProjectType } from 'src/app/labour-contractor-registration/labour-model/labour-contractor-project-type';
import { LabourContractorGeoLocation } from 'src/app/labour-contractor-registration/labour-model/labour-contractor-geo-location';
import { LabourContractorPreferredLocation } from 'src/app/labour-contractor-registration/labour-model/labour-contractor-preferred-location';
import { LabourContractorReference } from 'src/app/labour-contractor-registration/labour-model/labour-contractor-reference';
import { LabourContractorDocuments } from 'src/app/labour-contractor-registration/labour-model/labour-contractor-documents';

@Component({
	selector: 'app-labour-registration-detail',
	templateUrl: './labour-registration-detail.component.html',
	styleUrls: ['./labour-registration-detail.component.css']
})
export class LabourRegistrationDetailComponent implements OnInit {
	previousType: any = '';
	labourDetails: LabourContractor = new LabourContractor();
	city: City = new City();
	entity: Entity =  new Entity();
	state: State =  new State();
	country: Country =  new Country();
	project_type: Array<LabourContractorProjectType> = [];
	labourcontractorlocation_set: Array<LabourContractorGeoLocation> = [];
	labourcontractorpreferredlocation_set: Array<LabourContractorPreferredLocation> = [];
	labourcontractorreference_set: Array<LabourContractorReference> = [];
	lab_contractor_documents_set: Array<LabourContractorDocuments> = [];
	

	constructor(
		private router: Router,
		private labourService:  LabourContractorService,
		private activateRoute: ActivatedRoute
	) { }

	ngOnInit() {		
		this.activateRoute.params.subscribe(param => {
			console.log('param id ', param.id);
			this.previousType = param.flag;
			this.getLabourDetails(param.id);
		})
	}

	back() {
		//this.router.navigateByUrl(this.previousURL)
		this.router.navigate(['/sub-contractors', this.previousType])
	}

	getLabourDetails(labourId){
		this.labourService.getRequest('get-lc-list/?lc_id=' + labourId).subscribe(res=> {			
			// console.log('res ', res);
			this.labourDetails = res[0];
			this.city = this.labourDetails.city;
			this.entity = this.labourDetails.entity;
			this.state = this.labourDetails.city.state;
			this.country = this.labourDetails.city.country;
			this.project_type = this.labourDetails.project_type;
			this.labourcontractorlocation_set = this.labourDetails.labourcontractorlocation_set;
			this.labourcontractorpreferredlocation_set = this.labourDetails.labourcontractorpreferredlocation_set;
			this.labourcontractorreference_set = this.labourDetails.labourcontractorreference_set;
			this.lab_contractor_documents_set = this.labourDetails.lab_contractor_documents_set;
			
			console.log('this.labourDetails ', this.labourDetails);
			console.log('this.city ', this.city);
		})
	}

	
}
