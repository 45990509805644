import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const headers= new HttpHeaders()
  // .set('qt_api_key', '1b0c6d6c-3a5d-4f6d-befa-85145973537f')
  .set('qt_api_key', '73642a35-6e18-4049-91b9-91aae1e6f2bb')
  .set('qt_agency_id', '7ea40db7-04f9-4c03-86b8-ecee425be29c')
  
export class APIResponse{
  status:string;
  code:number;
  data:any;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  eForceBaseURL='http://3.7.163.243:4800'; // Production

  // https://eforce.cap-tech.in:4810/
  // eForceBaseURL='http://65.0.63.7:4807'; // Production
  // eForceBaseURL='http://43.241.39.78:4808'; // Dev



  // PANGSTBaseURL="http://43.241.39.78:4208/"  //dev
  PANGSTBaseURL="http://3.7.163.243:4200/"     //pro


  constructor(private http: HttpClient) { }

   // function for all API request 
   request(requestType: string, requestURL: string, requestBody: any): Observable<APIResponse> {

    // for get request..
    if (requestType === 'get') {
      return this.http.get<APIResponse>(this.eForceBaseURL + requestURL );
    }

    // for post request(adding)..
    if (requestType === 'post') {
      return this.http.post<APIResponse>(this.eForceBaseURL + requestURL, requestBody);
    }

    // for put request(updating with all required values)..        
    if (requestType === 'put') {
      return this.http.put<APIResponse>(this.eForceBaseURL + requestURL, requestBody);
    }

    // for patch request(updating with specific values)..
    if (requestType === 'patch') {
      return this.http.patch<APIResponse>(this.eForceBaseURL + requestURL, requestBody);
    }

    // for delete request..
    if (requestType === 'delete') {
      return this.http.delete<APIResponse>(this.eForceBaseURL + requestURL);
    }

  }

  // api for PAN verification
  getprojecttype()
  {

    return this.http.get("http://3.7.163.243:4100/project_type/get/?is_active=True&show=All");
  }


  commonApi(data)
  {
    return this.http.post("https://prod.aadhaarapi.com/pan-lite",data,{ headers: headers })
  }
 
  //pan verification


  kycAutomation(requestType: string, requestURL: string, requestBody: any){

    if (requestType === 'post') {

      return this.http.post(this.eForceBaseURL+"/kyc/" + requestURL, requestBody);

    }

    // for get request..
    if (requestType === 'get') {

      return this.http.get(this.eForceBaseURL+"/kyc/" + requestURL);

    }
    // for get request..
    if (requestType === 'put') {

      return this.http.patch(this.eForceBaseURL+"/kyc/" + requestURL,requestBody);

    }
  }
  

  getPanVerification(data)
  {
    // return this.http.post( this.PANGSTBaseURL+"pan_verify/",data)
    return this.http.post(this.PANGSTBaseURL+"pan_verify/",data)
  }

  getGstVerification(data)
  {
    // return this.http.post(this.PANGSTBaseURL+"gst_verify/",data)
    return this.http.post(this.PANGSTBaseURL+"gst_verify/",data)
  }

}
