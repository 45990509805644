import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AdminAuthService } from './admin-auth.service';

@Injectable({
	providedIn: 'root'
})
export class AdminService {
	private captechBaseURL = environment.captechBaseURL;
	private bearerToken: any = {}; 
	
	constructor(
		private http: HttpClient,
		private authService: AdminAuthService
	) {
		// set bearer token..
		let beare = 'Bearer ' + this.authService.getJWTToken();		
		this.bearerToken = new HttpHeaders().set('Authorization', beare)
	}

	// get token from auth-login api..
	authenticateUser(email, password):Observable<any> {
		let header = new HttpHeaders().set('Authorization', 'ab2dc22a-ba9d-11ea-b3de-0242ac130004');
		return this.http.post(this.captechBaseURL + 'authenticate/', { email, password }, { headers: header });
	}

	// get sub contractor list..
	getSubContractorList(para): Observable<any> {
		return this.http.get(this.captechBaseURL + para, { headers: this.bearerToken });
	}
}
