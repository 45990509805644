import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, finalize } from 'rxjs/operators';
import { HttpService } from './sub-contractor/http.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  private totalRequests = 0
  constructor( private spinner: NgxSpinnerService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.totalRequests++
    this.spinner.show();
    // const headerToken = "12ahsbsb2-12jzc-12zcndfnfd";
    const reqWithAuth = req.clone({
      setHeaders: {
        // Authorization: `Bearer ${localStorage.getItem('token')}`
        // qt_api_key:'1b0c6d6c-3a5d-4f6d-befa-85145973537f',
        // qt_agency_id:'7ea40db7-04f9-4c03-86b8-ecee425be29c',

      }
    })

    return next.handle(reqWithAuth).pipe(

      // retry(1),
     
      finalize(() => {

        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.spinner.hide()
        }

      }),
        catchError((error: HttpErrorResponse) => {



          console.log("error",error.error.data);

          if(error.error.data[0]['response_message'])
          {
            alert(error.error.data[0].response_message);

          } else{
             //console.log("http response", error);
          for (let i = 0; error.error.data.length >= i; i++) {

            // if(error)

            //console.log("innder http", error.error.data);

            if(error.error.data[0].response_msg)
            {

              alert(error.error.data[0].response_msg);
            }
            else{

              alert(error.error.data[i].field+ '-' + error.error.data[i].message);
            }

            
            

       
        }

          }


         
          

         

          return throwError(error)

        })

      );






  }
}
