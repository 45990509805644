import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  usersData: any = [
    { first_name: 'Test', last_name: 'Test', email_address: 'Test@test.com', role:'Test', action:'update, delete' },
    { first_name: 'Test1', last_name: 'Test1', email_address: 'Test1@test.com', role:'Test1', action:'update, delete' },
    { first_name: 'Test2', last_name: 'Test2', email_address: 'Test2@test.com', role:'Test2', action:'update, delete' },
    { first_name: 'Test3', last_name: 'Test3', email_address: 'Test3@test.com', role:'Test3', action:'update, delete' },
    { first_name: 'Test4', last_name: 'Test4', email_address: 'Test4@test.com', role:'Test4', action:'update, delete' },
    { first_name: 'Test5', last_name: 'Test5', email_address: 'Test5@test.com', role:'Test5', action:'update, delete' },
    { first_name: 'Test6', last_name: 'Test6', email_address: 'Test6@test.com', role:'Test6', action:'update, delete' },
    { first_name: 'Test7', last_name: 'Test7', email_address: 'Test7@test.com', role:'Test7', action:'update, delete' },
    { first_name: 'Test8', last_name: 'Test8', email_address: 'Test8@test.com', role:'Test8', action:'update, delete' }   
  ];
	usersDisplayedColumns: any = ['first_name', 'last_name', 'email_address', 'role', 'action'];

  constructor() { }

  ngOnInit() {
  }

}
