import { Component, OnInit } from '@angular/core';
import { AdminAuthService } from '../admin-auth.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	isShowAddUser: boolean = false;
	
	constructor(private authService: AdminAuthService) { }

	ngOnInit() {
		let userPayload = this.authService.getUserPayload();
		if(userPayload.role == 'super_user') {
			this.isShowAddUser = true;	
		}
	}
}
