import { Injectable } from '@angular/core';
import * as jwt_decode from "jwt-decode";

@Injectable({
	providedIn: 'root'
})
export class AdminAuthService {

	constructor() { }

	// set token in session..
	setTokenInSession(token) {
		sessionStorage.removeItem('token');
		sessionStorage.setItem('token', token);
		sessionStorage.setItem('user', JSON.stringify(this.getDecodedAccessToken(token)));
	}

	// get jwt token from session..
	getJWTToken(){
		return sessionStorage.getItem('token');
	}

	// get user payload from session..
	getUserPayload(){
		return JSON.parse(sessionStorage.getItem('user'));
	}

	//  clear session storage..
	clearSession(){
		sessionStorage.clear();
	}

	// decode JWT token in to payload..
	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		}
		catch (Error) {
			return null;
		}
	}
}
