import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LabourContractorService } from '../labour-contractor-registration/labour-contractor.service';

declare var $: any;

@Component({
	selector: 'app-backoffice',
	templateUrl: './backoffice.component.html',
	styleUrls: ['./backoffice.component.css']
})
export class BackofficeComponent implements OnInit {

	languageVal: number;
	isFormValid: boolean = false;
	exceptionPopUpObj: any = {
		isPopUpOpen: false,
		popUpContent: '',
	};

	constructor(private http: HttpClient, private labourService: LabourContractorService) { }

	ngOnInit() {
		this.languageVal = 0;
	}

	onFormSubmit(form: NgForm) {
		this.isFormValid = true;
		console.log('[form] ', form);		

		if (form.valid) {

			if(form.value.source == "0") {
				this.exceptionPopUpObj.popUpContent = 'Source value invalid';
				this.exceptionPopUpObj.isPopUpOpen = true;
				return false;
			}

			let smsContent = 'http://cap-tech.in/sub-contractor-registration/' + form.value.source + '/' + form.value.language;

			this.labourService.sendSMS('http://sms.businesskarma.in/api/v2/sms/send?access_token=bd1de98a316444989c1708ed3525b4cf&service=T&type=U&sender=EFORCE&message=' + smsContent + '&to=' + form.value.mobileNo).subscribe(res => {
				if(res.status == 200){
					this.exceptionPopUpObj.popUpContent = 'SMS sent successfully!!';
					this.exceptionPopUpObj.isPopUpOpen = true;					
					form.resetForm();
					$('select[name="language"]').val('');
				}
			})
		}
	}

	onlyNumbers(event: any) {
		var charCode = (event.which) ? event.which : event.keyCode
		if ((charCode >= 48 && charCode <= 57))
			return true;
		return false;
	}
}
