import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  GifState=true;

  
  imageObject = [
    
    {
    video: '../../assets/referral/close.png',
    }
    

];


  ngOnInit() {

    $(document).ready(function () {
      $('#myModal').modal('show');
  })



    

  
      setTimeout(() => {
        
        this.hideGif();
      }, 2000);

    // setInterval(()=>{

    //   if(this.GifState)
    //   {
    //     this.hideGif();
    //   }else{
        
    //     this.ShowGif();
    //   }
      
    // }, 5000 )


  }


  
  ShowGif()
  {
   
    document.getElementById('gif-letf').style.display="block";
    document.getElementById('gif-right').style.display="none";
    this.GifState=true
  }

  hideGif()
  {
   
    this.GifState=false;
    
    document.getElementById('gif-letf').style.display="none";
    document.getElementById('gif-right').style.display="none";
  }




}
