import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { HttpService } from './http.service';
import { fn } from '@angular/compiler/src/output/output_ast';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TransitionCheckState } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;



@Component({
    selector: 'app-sub-contractor',
    templateUrl: './sub-contractor.component.html',
    styleUrls: ['./sub-contractor.component.css']
})
export class SubContractorComponent implements OnInit {
    lang: any;
    isPopUpOpen: boolean;
    extraInfo: any;
    projectTypePagination: any;
    ProjectTypeData: any;
    entityData: any;
    city: any;
    contractorType: any;
    unique_identification_list: any;
    settings = {};
    value = "";
    singleSelectSettings: { text: string; classes: string; enableSearchFilter: boolean; lazyLoading: boolean; singleSelection: boolean; };
    stateName: any;
    cityId: any;
    selectedCityList = [];
    multiSelectSettings: {
        text: string;
        classes: string; enableSearchFilter: boolean; lazyLoading: boolean;
    };
    dropdownList: { id: number; itemName: string; }[];
    currentDate: Date;
    Todaysdate: any;
    formGroup: FormGroup;
    minDate: Date;
    maxDate: Date;
    Lcdob: string;
    parmsId: any;
    Pcity: any;
    Gcity: any;
    companyName: any;
    GstVerifiedName: any;
    gstVerifiedStatus: boolean = false;
    NotMatchGst: any;
    GstNo: any;
    NotMatchPan: boolean;
    PanVerifiedStatus: boolean;
    OwnerName: any;
    panNumber: any;
    readOnlyPanName: any;
    gstVerifiedStatus2 = true;
    annualTurnOver: any=null;
    // private _formBuilder: any;

    constructor(private spinner: NgxSpinnerService, private routes: ActivatedRoute, private translate: TranslateService, private router: Router, private http: HttpService, private _formBuilder: FormBuilder) { }

    ngOnInit() {





        this.currentDate = new Date();

        this.Todaysdate = formatDate(this.currentDate, 'yyyy-MM-dd', 'en-US');


        // ////console.log("route value", this.routes.snapshot.params.id);

        this.parmsId = this.routes.snapshot.params.id


        this.formGroup = new FormGroup({

            company_name: new FormControl('', Validators.required),
            // pan_verified: new FormControl(false),
            // gst_verified: new FormControl(false),

            address: new FormControl('', Validators.required),
            entity_id: new FormControl('', Validators.required),
            // terms: new FormControl("", Validators.required),
            city_id: new FormControl(),
            pincode: new FormControl(''),
            no_of_projects_completed: new FormControl(0),
            gst_no: new FormControl('',  Validators.pattern(
                '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$'
              ),),
            annual_turnover: new FormControl(''),
            pan_no: new FormControl('', [Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')]),
            pf_no: new FormControl(''),
            type_of_contractor_id: new FormControl(),
            unique_identification_id: new FormControl(),
            description_of_work: new FormControl(''),
            website: new FormControl(''),
            esic: new FormControl(''),
            certification: new FormControl(''),
            source: new FormControl(this.parmsId),
            whats_app_no: new FormControl('', Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")),
            project_type: this._formBuilder.array([
                this._formBuilder.group({
                    project_type_ids: new FormControl("", Validators.required),

                })

            ]),
            geo_spred: this._formBuilder.array([
                this._formBuilder.group({
                    location_ids: new FormControl(""),
                })

            ]),
            preferred_location: this._formBuilder.array([
                this._formBuilder.group({
                    location_ids: new FormControl("", Validators.required),
                })

            ]),
            owner_details: new FormGroup({
                name: new FormControl('', Validators.required),
                email_address: new FormControl('', Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")),
                mobile_no: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
                dob: new FormControl(null),
                tc_accepted:new FormControl('', Validators.required),
                whatsapp_accepted:new FormControl(false),
                department: new FormControl('LC'),
                language: new FormControl('en-US'),
                role: new FormGroup({
                    role: new FormControl(3)
                })

            }),

            reference: this._formBuilder.array([
                this.initItemRows()

            ]),

        })


        this.getProjectType();
        // this.getCity();
        this.getEntity();
        this.getTypeOFContractor();
        this.getListOFUniqueIdentification();

        for (let i = 1; i < 2; i++) {
            this.formArr.push(this._formBuilder.group({
                seq_no: [i + 1],
                ref_company_name: [''],
                ref_contact_no: ['',Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
                ref_name: [''],
                ref_project_name: [''],
            }));
        }

        // ////console.log("form values-->", this.formGroup.value);


    }


    ////////////////////////////
    // get all required api data
    ///////////////////////////////

    ESICLimit(event: any) {
        var charCode = event.which ? event.which : event.keyCode;
        if (event.target.value.length <= 16 && charCode != 101 && charCode != 43 && charCode != 46 && charCode != 32
          && charCode >= 48 && charCode <= 57 && charCode != 69) {
          return true;
        } else {
          return false;
        }
      }

    // get project type 
    getProjectType() {
        this.http.getprojecttype().subscribe(res => {
            this.projectTypePagination = res;
           
            this.ProjectTypeData = this.projectTypePagination.data.results;
            // this.ProjectTypeData.forEach(x=>{
            //     x.project_type_name = x.project_type_name.toString().replace('&','~');
            // });
            ////console.log("project data", this.ProjectTypeData);


           
        })
    }
    //get entity
    getEntity() {
        this.http.request('get', '/entity/get/', null).subscribe(res => {
            this.entityData = res.data;

        })
    }

    getcity(event) {
        if (event.term.length == 3) {
            this.http.request('get', '/city/get/?city_name=' + event.term, null).subscribe(res => {
                this.city = res.data;
            })
        }
    }

    getPreferedLocation(event) {
        if (event.term.length == 3) {
            this.http.request('get', '/city/get/?city_name=' + event.term, null).subscribe(res => {
                this.Pcity = res.data;
            })

        }
    }

    getGeoSpred(event) {
        if (event.term.length == 3) {
            this.http.request('get', '/city/get/?city_name=' + event.term, null).subscribe(res => {
                this.Gcity = res.data;
            })

        }
    }



    // get state
    getStateAndCountry(event) {
        let cityObj = this.city.find(item => item.id == event);
        if (cityObj) {
            this.stateName = cityObj['state']['state_name'];
        }
    }

    //type of contractor

    getTypeOFContractor() {
        this.http.request('get', "/material_types/get/", null).subscribe(res => {
            this.contractorType = res.data;
        })
    }

    //list of unique-identification

    getListOFUniqueIdentification() {
        this.http.request('get', "/unique-identification/get/?is_active=1&show=All", null).subscribe(res => {
            this.unique_identification_list = res.data.results;
        })
    }


    // get company name

    GetCoampnyName(name) {
        let Name = name.toUpperCase()
        let CName = Name.split(" ");
        this.companyName = CName

        // ////console.log("comapny name",this.companyName);
        
    }

    ownerName(name) {

        let Name = name.toUpperCase()
        let CName = Name.split(" ");
        this.OwnerName = CName

    }



    //  varification
    panVerification(val) {
        
        this.panNumber = val;
    }


    searchPan(val) {

        if (val.length == 10) {
            this.http.getPanVerification({
                "pan_no": val
            }).subscribe(res => {

               //consol.log("pan verification data", res);
                
                let resData = res['data'][0];
                this.readOnlyPanName = resData['result']['user_full_name'];
            });

        }

    }


    gstKeyPress(data)
    {
        console.log("data",data.target.value.length);

        if(data.target.value.length==0)
        {
            this.NotMatchGst=false;
            this.gstVerifiedStatus2=true;
            this.annualTurnOver=null
        }
        if (data.target.value.length <= 15) {
            return true;
          } else {
            return false;
          }
        
    }


    gstVerification(val) {

        if (val.length == 15) {

            this.GstNo = val
            this.http.getGstVerification({
                "gst_no": val
            }).subscribe(result => {
                // ////console.log("gst result", result);
                
               //consol.log("gst verification", result);
                

                this.annualTurnOver = result['data'][0]['result']['aggregate_turn_over'];
                let res = result['data'][0];
                if (res['response_code'] == "100") {
                    this.GstVerifiedName = res['result']['trade_name'];
                    let split = this.GstVerifiedName.toUpperCase().split(" ");

                    // ////console.log("gst trade name", split);
                    

                    for (let i = 0; i < this.companyName.length; i++) {
                        if (split[0] == this.companyName[i]) {
                            this.NotMatchGst = false;
                            this.gstVerifiedStatus2 = true;
                            return this.gstVerifiedStatus = true;
                        }
                        else {
                            this.NotMatchGst = true;
                            this.gstVerifiedStatus2 = false;
                            this.gstVerifiedStatus = false;
                        }


                    }


                }
                else {

                  
                    this.NotMatchGst=true;
                    alert("Somthing Went Wrong please try again")

                }



            },error=>{
                console.log("error ",error);

                if(error.error.status_code==400){
                    this.NotMatchGst=true;
                }
                
            })
        }

        if(val.length==0)
        {
            this.gstVerifiedStatus2=true;
         
            this.NotMatchGst=false;

            this.formGroup.patchValue({
                annual_turnover: this.annualTurnOver,
               
            });

            this.annualTurnOver=null


        }
    }



    ///////////////////////////////
    // creating form group
    /////////////////////////////

    get formArr() {
        return this.formGroup.get('reference') as FormArray;
    }

    initItemRows() {
        return this._formBuilder.group({
            seq_no: [1],
            ref_company_name: [''],
            ref_contact_no: [''],
            ref_name: [''],
            ref_project_name: [''],
        });
    }




    onSubmit() {


        
        
        
        this.formGroup.patchValue({
            annual_turnover: this.annualTurnOver,
            owner_details: {
                department: 'LC',
                language: 'en-US',
                // dob: this.Lcdob ? this.Lcdob : null,
                role: {
                    role: 3
                }
                
            }
        });

        // check terms and condition is accepted or not
        if(this.formGroup.value.owner_details.tc_accepted)
        {

            
            // verifiing gst 

            if (this.formGroup.valid) {
    
                if (this.gstVerifiedStatus2 || this.formGroup.value.gst_no==null) {
    
                    if (this.panNumber.length == 10) {
    
                        this.http.getPanVerification({
                            "pan_no": this.panNumber
                        }).subscribe(res => {
                           //consol.log("result of pan verification", res);
    
                            let resData = res['data'][0]
    
                            if (resData['response_code'] == 100 && resData['response_message'] == 'Valid Authentication') {
                                // ////console.log("campany anme->", this.companyName);
    
                                let panCompanyName = resData['result'];
    
                               //consol.log("varified pan name-->", panCompanyName);
                                let split = panCompanyName['user_full_name'].split(" ")

                                
    
                                // ////console.log("split value", split);
    
                                if (panCompanyName['pan_status'] == "VALID") {
    
                                    for (let i = 0; i < this.companyName.length; i++) {
    
                                        
                                        let uppercar=split[0].toUpperCase()
    
                                        if (uppercar == this.companyName[i]) {
    
                                            this.NotMatchPan = false;
    
                                            //console.log("verifiy by company name");

                                            
                                            // this.formGroup.patchValue({
                                            //     pan_verified:true
                                            // })

    
                                            this.SaveData();
                                            this.PanVerifiedStatus = true;
                                            return
                                        }
                                        else {
    
                                            this.NotMatchPan = true;
                                            this.PanVerifiedStatus = false;
    
                                        }
    
                                    }

                                    let uppercar1=split[0].toUpperCase()
                                    
                                    for (let i = 0; i < this.OwnerName.length; i++) {
                                        if (uppercar1 == this.OwnerName[i]) {
                                            //console.log("verfied user from owner by woner name ");
                                            this.NotMatchPan = false;
                                            
                                            // this.formGroup.patchValue({
                                            //     pan_verified:true
                                            // })
    
                                            this.SaveData();
                                            this.PanVerifiedStatus = true;
                                            return
                                        }
                                        else {
                                            // ////console.log("uun authrised dum");
                                            this.NotMatchPan = true;
                                            this.PanVerifiedStatus = false;
    
    
                                        }
    
                                    }
    
                                    if (this.PanVerifiedStatus) {
    
    
                                    }
                                    else {
                                        alert("PAN details doesnot match with name or company name")
    
                                    }
    
                                }
    
    
                            }
                            else {
    
                                if (resData['response_code'] == 99 || 100 || 105 || 110 || 104) {
    
                                    alert(" Somthing went wrong");
    
                                }
    
                                if (resData['response_code'] == 102) {
                                    alert("PAN details doesn't match with name or company name");
                                }
                                else {
                                    alert("Invalid PAN details");
                                }
                            }
    
                        })
    
                    }
    
                } else {
    
                    alert("GST details doesnot match with  comoany name")
    
                }
    
    
    
    
            }
    

        }else{
            alert("Please accept Terms and Condition ...!")
        }
        
       


     
    }



    // update user score
    
  kycScoreUpdate(id){ 
    this.http.kycAutomation('get','lc_kyc_update?lc_id='+id,null).subscribe(res=>{
        console.log("res",res);
        
      })
  }


    //==========================================================
    //  method for accept only integer number. for pin
    //==========================================================
    onlyPin(event: any) {
        if (event.target.value.length <= 5) {
            return true;

        } else {
            return false;
        }

    }



    //==========================================================
    //  method for accept only integer number. mobile
    //==========================================================
    onlyNumbers(event: any) {
        if (event.target.value.length <= 9) {
            return true;

        } else {
            return false;
        }

    }



    SaveData() {

        this.spinner.show();

        this.http.request('post', '/sub_contractor/create/', this.formGroup.value).subscribe(res => {



            console.log("creatinon data",res);

            let id=res['data'][0]['id']

            this.kycScoreUpdate(id)
            

            $(document).ready(function () {
                $('#myModal').modal('show');
            })
            this.gstVerifiedStatus = false;
            this.PanVerifiedStatus = false;
            this.ngOnInit()



        })
    }



}




