import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Router, NavigationEnd } from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class APIResponse {
    status: string;
    code: number;
    data: any;
}

export class LabourContractorService {
    private options: Object;
    private captechBasURL = environment.captechBaseURL; // captech base URL..
    private eForceBasURL = environment.eForceBaseURL; // eForce base URL..
    private authorizationKey = 'ab2dc22a-ba9d-11ea-b3de-0242ac130004';

    constructor(private http: HttpClient, private router: Router) {
        // common apis.
        this.options = {
            headers: new HttpHeaders({
                'Authorization': this.authorizationKey,
            })
        }
    }

    // baseUrlForCommon = 'http://43.241.39.78:4802/';

    // baseUrlForCommon = 'http://3.7.163.243:4800/';


    // request(requestType: string, requestURL: string, requestBody: any) {
    //     // for post request(adding)..
    //     if (requestType === 'get') {
    //         return this.http.get<APIResponse>(this.baseUrlForCommon + requestURL, requestBody);
    //     }
    // }

    // common get 
    getRequest(getProp): Observable<any> {
        const concatURL = this.captechBasURL + getProp;
        return this.http.get(concatURL, this.options);
    }

    // common post 
    postRequest(getProp, body): Observable<any> {
        const concatURL = this.captechBasURL + getProp;
        return this.http.post(concatURL, body, this.options)
    }

    // common put 
    putRequest(getProp, body): Observable<any> {
        const concatURL = this.captechBasURL + getProp;
        return this.http.put(concatURL, body, this.options);
    }

    // common patch 
    patchRequest(url, body): Observable<any> {
        const concatURL = this.captechBasURL + url;
        return this.http.patch(concatURL, body, this.options);
    }

    // eforce post 
    eforcePost(url, body): Observable<any> {
        const concatURL = this.eForceBasURL + url;
        return this.http.post(concatURL, body);
    }

    // send sms
    sendSMS(url): Observable<any> {
        return this.http.get(url);
    }


}